import { ReactNode } from "react";

import { NoAccess } from "@saas/account/ui";
import { AccessesKeyEnum } from "@saas/account/utils";
import { useLayoutShowAside } from "@saas/layout/utils";
import { ProgressBar, SlideOver } from "@saas/shared/ui";
import { useProgress } from "@saas/shared/utils";

import {
  CashierTopNavigationBar,
  Navigation,
  PosNavigation,
  Snackbar,
} from "..";

export interface PosLayoutProps {
  children: ReactNode;
  accountMenu: () => JSX.Element;
  marketplaceConnectionBlockerModal: () => JSX.Element;
  navigation: ReadonlyArray<Navigation>;
  unverifiedAccountBlockerModal: () => JSX.Element;
  enabledNotificationR2?: boolean;
  isProfileLoaded?: boolean;
  accesses?: AccessesKeyEnum[];
  hasAccess: boolean;
  hasParentId: string | null;
  handleLogout: () => void;
}

export const PosLayout = ({
  accountMenu,
  marketplaceConnectionBlockerModal: MarketplaceConnectionBlockerModal,
  navigation,
  unverifiedAccountBlockerModal: UnverifiedAccountBlockerModal,
  enabledNotificationR2,
  children,
  isProfileLoaded,
  accesses,
  hasAccess,
  hasParentId,
  handleLogout,
}: PosLayoutProps) => {
  const progressBarProps = useProgress();

  const { setShowAside, showAside } = useLayoutShowAside();

  const isParentIdExist = hasParentId !== null;
  const isAccessesExist = accesses && accesses?.length > 0;
  const isSubaccount = isAccessesExist && isParentIdExist;

  const hasPermissions = hasAccess || !isSubaccount;

  return (
    <div className={"bg-neutral-N100 flex min-h-screen flex-col"}>
      <div className={"hidden md:block"}>
        <PosNavigation navigation={navigation} />
      </div>
      <div className={"block md:hidden"}>
        <SlideOver
          origin={"left"}
          size={"small"}
          open={showAside}
          onClose={() => setShowAside(false)}
        >
          <PosNavigation
            navigation={navigation}
            onNavigate={() => setShowAside(false)}
          />
        </SlideOver>
      </div>

      <div className={"flex flex-1 flex-col md:pl-[248px]"}>
        <CashierTopNavigationBar
          accountMenu={accountMenu}
          handleShowAside={() => setShowAside(true)}
          enabledNotificationR2={enabledNotificationR2}
        />

        <ProgressBar
          {...progressBarProps}
          className={"top-[58px] left-auto right-0 md:w-[calc(100%_-_248px)]"}
        />

        <main className={"relative flex min-h-full w-full flex-1 flex-col"}>
          <div>
            {hasPermissions ? (
              <>
                <Snackbar className={"pt-[58px] md:pl-[248px]"} />

                {isProfileLoaded ? <UnverifiedAccountBlockerModal /> : null}

                <MarketplaceConnectionBlockerModal />

                <div>{children}</div>
              </>
            ) : (
              <NoAccess handleLogout={handleLogout} variant={"pos"} />
            )}
          </div>
        </main>
      </div>
    </div>
  );
};

export default PosLayout;
