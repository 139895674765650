export type ICashierV2PaymentMethodEnum =
  typeof CashierV2PaymentMethodEnum[keyof typeof CashierV2PaymentMethodEnum];

export const CashierV2PaymentMethodEnum = {
  DEBIT: "debit",
  CREDIT_CARD: "credit_card",
  CASH: "cash",
  QRIS: "qris",
  E_WALLET: "e_wallet",
  STORE_CREDIT: "store_credit",
} as const;

export default CashierV2PaymentMethodEnum;
