export const CashierSessionStateEnum = {
  OPENING: "opening",
  IDLE: "idle",
  CLOSING: "closing",
  PREVIEW: "preview",
  DONE: "done",
} as const;

export type ICashierSessionStateEnum =
  typeof CashierSessionStateEnum[keyof typeof CashierSessionStateEnum];

export default CashierSessionStateEnum;
