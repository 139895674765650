// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { CartItemInterface } from "@saas/order/utils";
// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { PromoLabelEnum, StorePromo } from "@saas/promotion/utils";
import { Money } from "@saas/shared/utils";

import { getRoundedTotal } from "../..";

export type GetCashierPricingInput = {
  promos?: ReadonlyArray<StorePromo>;
  items: ReadonlyArray<CartItemInterface>;
  loyaltyPoints?: number;
  hasRoundingUp?: boolean;
  hasPayment?: boolean;
};

export type GetCashierPricingOutput = {
  originalPrice: Money;
  discountPrice: Money;
  totalPrice: Money;
  loyaltyPointsUsed?: Money;
  roundedUpAmount?: Money;
};

export const getCashierPricing = ({
  items,
  promos = [],
  loyaltyPoints,
  hasRoundingUp = false,
  hasPayment = false,
}: GetCashierPricingInput): GetCashierPricingOutput => {
  const totalProductPrice = items.reduce((total, data) => {
    return total.add(
      new Money(data.discountedPrice ?? data.price).times(data.quantity)
    );
  }, new Money(0));

  const totalPromosValue = promos.reduce((result, current) => {
    if (!hasPayment && current.label.value === PromoLabelEnum.PAYMENT_METHOD) {
      return result;
    }

    return result.add(current.grantedReward?.amount ?? 0);
  }, new Money(0));

  const totalDiscountPrice = totalProductPrice.subtract(totalPromosValue);

  const loyaltyPointsUsed = loyaltyPoints
    ? new Money(Math.min(loyaltyPoints, totalDiscountPrice.integer))
    : new Money(0);

  const totalPriceWithoutRounding =
    totalDiscountPrice.subtract(loyaltyPointsUsed);

  const { total: totalPrice, roundedDifference: roundedUpAmount } =
    getRoundedTotal({
      total: totalPriceWithoutRounding.integer,
      isRounded: hasRoundingUp,
    });

  return {
    originalPrice: totalProductPrice,
    discountPrice: totalPrice.isPositive ? totalPrice : new Money(0),
    totalPrice: totalPrice.isPositive ? totalPrice : new Money(0),
    loyaltyPointsUsed: loyaltyPointsUsed.isPositive
      ? loyaltyPointsUsed
      : undefined,
    roundedUpAmount: new Money(roundedUpAmount),
  };
};

export default getCashierPricing;
