// TODO: Refactor Cashier into `store`
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  PromoLabelEnum,
  StorePromo,
  StorePromoModel,
} from "@saas/promotion/utils";

import { OrderProductInterface } from "../../";

export type GetOfflineOrderPromoInput = {
  promos?: ReadonlyArray<StorePromoModel>;
  discountFreeform?: number;
};

export type GetOfflineOrderPromoOutput = {
  sellerVoucherAmount: number;
  voucherCodes: ReadonlyArray<string>;
  discountProducts: ReadonlyArray<{
    name: string;
    masterProductVariantId: number;
    amount: number;
  }>;
  products: ReadonlyArray<OrderProductInterface>;
  discountProductsAmount: number;
};

export const getOfflineOrderPromo = ({
  promos = [],
  discountFreeform = 0,
}: GetOfflineOrderPromoInput): GetOfflineOrderPromoOutput => {
  const storePromos = promos.map((promo) => new StorePromo(promo));
  const sellerVoucherAmount =
    storePromos
      .filter(
        (promo) =>
          !promo.isProductBundlePromo &&
          promo.data.label !== PromoLabelEnum.PRODUCT_DISCOUNT
      )
      .reduce(
        (res, promo) => (promo.grantedReward?.amount?.integer ?? 0) + res,
        0
      ) + discountFreeform;
  const discountProductsAmount = storePromos
    .filter((promo) => promo.data.label === PromoLabelEnum.PRODUCT_DISCOUNT)
    .reduce(
      (res, promo) => (promo.grantedReward?.amount?.integer ?? 0) + res,
      0
    );

  const products = storePromos
    .filter((promo) => promo.isProductBundlePromo)
    .map((promo) => {
      const item = promo.grantedReward?.item;
      const {
        name = "",
        masterProductVariantId = 0,
        quantity = 1,
      } = item ?? {};

      return {
        imageUrl: "/images/product-image-placeholder.png",
        name: name,
        productId: masterProductVariantId,
        masterProductId: masterProductVariantId,
        variants: [
          {
            name: name,
            sku: "",
            imageUrl: "/images/product-image-placeholder.png",
            originalPrice: 0,
            discountedPrice: 0,
            quantity: quantity,
            returnedQuantity: 0,
            outOfStock: false,
            notFound: false,
            masterProductVariantId: masterProductVariantId,
            productVariantId: masterProductVariantId,
            isProblematicStock: false,
          },
        ],
      };
    });

  const discountProducts = storePromos
    .filter((promo) => promo.data.label === PromoLabelEnum.PRODUCT_DISCOUNT)
    .map((promo) => ({
      name: promo.schemas[0].rule.masterProductVariant?.name ?? "",
      masterProductVariantId: promo.schemas[0].rule.masterProductVariantId ?? 0,
      amount: promo.grantedReward?.amount?.integer ?? 0,
    }));

  const voucherCodes = storePromos.map((promo) => promo.title);

  return {
    sellerVoucherAmount,
    products,
    discountProducts,
    voucherCodes,
    discountProductsAmount,
  };
};

export default getOfflineOrderPromo;
