import { useMemo } from "react";

import { useFlagStatus } from "@saas/flags/feature";
import {
  ChannelEnum,
  PaginationInterface,
  SyncStatusEnum,
} from "@saas/shared/utils";
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import {
  CASHIER_V2_MACHINE_STORAGE_KEY,
  CashierV2MachineContext,
  initialCashierV2Context,
} from "@saas/store/utils";

import {
  CashierOfflineOrderInterface,
  generatePagination,
  getOfflineOrderPromo,
  getOfflineOrderStatus,
  getOfflineProducts,
  getOfflineTotalPrice,
  mapOfflineOrderToCashierOrder,
  OrderModel,
  OrderSearchTypeEnum,
  OrderStatusEnum,
  PaymentMethodEnum,
} from "../../";

import { useLocalStorage } from "usehooks-ts";

export const OFFLINE_ORDER_KEY = "offline_order";

export type UseOfflineOrdersInputType = {
  currentPage: number;
  perPage: number;
  search?: string;
  searchType?: OrderSearchTypeEnum;
};

export type UseOfflineOrdersReturnType = {
  orders: ReadonlyArray<OrderModel>;
  pagination: PaginationInterface;
  count: {
    [OrderStatusEnum.COMPLETED]: number;
    [OrderStatusEnum.OUTSTANDING]: number;
    [OrderStatusEnum.OUTSTANDING_OVERDUE]: number;
  };
  rawOfflineOrder: ReadonlyArray<CashierOfflineOrderInterface>;
  handleSetOfflineOrders: (data: Array<CashierOfflineOrderInterface>) => void;
  handleSetOfflineOrdersV2: (
    data: ReadonlyArray<CashierOfflineOrderInterface>
  ) => void;
};

export const useOfflineOrders = ({
  perPage,
  currentPage,
  search,
  searchType,
}: UseOfflineOrdersInputType): UseOfflineOrdersReturnType => {
  const posMachineImprovementFlag = useFlagStatus("pos/machine-improvement");

  const [offlineOrdersV1, setOfflineOrders] = useLocalStorage<
    ReadonlyArray<CashierOfflineOrderInterface>
  >(OFFLINE_ORDER_KEY, []);

  const [cashierV2Context, setCashierV2Context] =
    useLocalStorage<CashierV2MachineContext>(
      CASHIER_V2_MACHINE_STORAGE_KEY,
      initialCashierV2Context
    );

  const offlineOrdersV2 = useMemo<ReadonlyArray<CashierOfflineOrderInterface>>(
    () =>
      cashierV2Context.offlineOrders.map((offlineOrder) => ({
        ...offlineOrder,
        id: offlineOrder.uuid,
        payment: offlineOrder.payment,
        products: getOfflineProducts(offlineOrder.productVariants),
        createdAt: new Date(offlineOrder.createdAt),
        updatedAt: new Date(offlineOrder.createdAt),
        status:
          offlineOrder.payment.paymentMethod === PaymentMethodEnum.INVOICE
            ? OrderStatusEnum.OUTSTANDING
            : OrderStatusEnum.COMPLETED,
        promos: [],
      })),
    [cashierV2Context.offlineOrders]
  );

  const handleSetOfflineOrdersV2 = (
    orders: ReadonlyArray<CashierOfflineOrderInterface>
  ) => {
    const offlineOrders = orders.map((order) =>
      mapOfflineOrderToCashierOrder(order)
    );

    setCashierV2Context({
      ...cashierV2Context,
      offlineOrders,
    });
  };

  const offlineOrders = useMemo(() => {
    return posMachineImprovementFlag ? offlineOrdersV2 : offlineOrdersV1;
  }, [offlineOrdersV1, offlineOrdersV2, posMachineImprovementFlag]);

  const pagination = useMemo(() => {
    return generatePagination({
      perPage,
      currentPage,
      totalItems: offlineOrders.length,
    });
  }, [perPage, currentPage, offlineOrders.length]);

  const orders = useMemo(() => {
    const orders = offlineOrders
      .filter((offlineOrder: CashierOfflineOrderInterface) => {
        if (searchType === OrderSearchTypeEnum.ORDER_NO && search) {
          return offlineOrder.id.toLowerCase() === search.toLowerCase();
        } else if (searchType === OrderSearchTypeEnum.AWB_NUMBER && search) {
          return (
            (offlineOrder.awbNumber ?? "").toLowerCase() ===
            search.toLowerCase()
          );
        }

        return true;
      })
      .map((offlineOrder: CashierOfflineOrderInterface) => {
        const offlineOrderPromo = getOfflineOrderPromo({
          promos: offlineOrder.promos,
          discountFreeform:
            offlineOrder.payment.paymentModifier?.discountFreeform,
        });

        const totalPrice = getOfflineTotalPrice({
          productVariants: offlineOrder.productVariants,
          sellerVoucherAmount: offlineOrderPromo.sellerVoucherAmount,
          discountProductsAmount: offlineOrderPromo.discountProductsAmount,
        });

        return {
          id: 0,
          orderNo: offlineOrder.id,
          orderId: offlineOrder.id,
          invoiceNumber: "",
          status: getOfflineOrderStatus(offlineOrder.payment),
          store: {
            name: "",
            channel: ChannelEnum.OFFLINE,
          },
          products: [
            ...offlineOrder.products,
            ...(offlineOrderPromo.products ?? []),
          ],
          totalPrice,
          syncStatus: SyncStatusEnum.DONE,
          createdAt: new Date(offlineOrder.createdAt),
          updatedAt: new Date(offlineOrder.updatedAt),
          shippingLabelPrintCounter: 0,
          recipientName: offlineOrder.recipient?.name,
          payment: offlineOrder.payment,
        };
      })
      .slice(pagination.fromItem - 1, pagination.toItem);

    return orders.reverse() ?? [];
  }, [
    offlineOrders,
    pagination.fromItem,
    pagination.toItem,
    searchType,
    search,
  ]);

  if (!offlineOrders.length) {
    return {
      orders: [],
      pagination: {
        totalItems: 0,
        perPage: 50,
        currentPage: 1,
        totalPage: 1,
        toItem: 0,
        fromItem: 0,
        sort: "",
        nextUrl: "",
        previousUrl: "",
      },
      count: {
        [OrderStatusEnum.COMPLETED]: 0,
        [OrderStatusEnum.OUTSTANDING]: 0,
        [OrderStatusEnum.OUTSTANDING_OVERDUE]: 0,
      },
      rawOfflineOrder: offlineOrders,
      handleSetOfflineOrders: setOfflineOrders,
      handleSetOfflineOrdersV2,
    };
  }

  return {
    orders,
    pagination,
    count: {
      [OrderStatusEnum.COMPLETED]: offlineOrders.filter(
        (order: CashierOfflineOrderInterface) =>
          order.status === OrderStatusEnum.COMPLETED
      ).length,
      [OrderStatusEnum.OUTSTANDING]: offlineOrders.filter(
        (order: CashierOfflineOrderInterface) =>
          order.status === OrderStatusEnum.OUTSTANDING
      ).length,
      [OrderStatusEnum.OUTSTANDING_OVERDUE]: offlineOrders.filter(
        (order: CashierOfflineOrderInterface) =>
          order.status === OrderStatusEnum.OUTSTANDING_OVERDUE
      ).length,
    },
    rawOfflineOrder: offlineOrders,
    handleSetOfflineOrders: setOfflineOrders,
    handleSetOfflineOrdersV2,
  };
};

export default useOfflineOrders;
