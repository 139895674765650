import { useState } from "react";

import { useFlagStatus } from "@saas/flags/feature";
import {
  ChatNotificationContainer,
  NotificationContainer,
} from "@saas/notification/feature";
import { MenuIcon } from "@saas/shared/icon";
import { Divider, Logo } from "@saas/shared/ui";
import { classNames } from "@saas/shared/utils";

import { useEventListener } from "usehooks-ts";

export interface CashierTopNavigationBarProps {
  accountMenu: () => JSX.Element;
  handleShowAside?: () => void;
  enabledNotificationR2?: boolean;
}

export const CashierTopNavigationBar = ({
  accountMenu: AccountMenu,
  handleShowAside,
  enabledNotificationR2,
}: CashierTopNavigationBarProps) => {
  const isChatNotificationEnabled = useFlagStatus("crm");

  const [sticky, setSticky] = useState(false);

  useEventListener("scroll", () => {
    window.scrollY > 0 ? setSticky(true) : setSticky(false);
  });

  return (
    <header
      className={classNames(
        "bg-neutral-N0 z-[5] flex flex-shrink-0 flex-row items-center justify-between gap-4 px-5 py-4 md:px-6 md:py-2",
        sticky && "shadow-elevation-1 sticky top-0"
      )}
    >
      <div className={"mr-auto flex items-center gap-4 md:hidden"}>
        {handleShowAside ? (
          <button
            onClick={handleShowAside}
            data-testid={"home__icon__hamburger-menu"}
            className={"flex"}
          >
            <MenuIcon />
          </button>
        ) : null}

        <Logo variant={"pos"} />
      </div>

      <div className={"ml-auto flex flex-row items-center justify-end gap-4"}>
        <div
          className={classNames(
            "flex-row items-center gap-4",
            "hidden md:flex"
          )}
        >
          {isChatNotificationEnabled ? <ChatNotificationContainer /> : null}

          <NotificationContainer
            enabledNotificationR2={enabledNotificationR2}
          />

          <Divider
            className={"min-h-[24px] md:min-h-[42px]"}
            orientation={"vertical"}
          />

          <AccountMenu />
        </div>
      </div>
    </header>
  );
};

export default CashierTopNavigationBar;
