import { ChannelEnum, formatReadableDate, Money } from "@saas/shared/utils";

import {
  DEFAULT_MINIMUM_ORDER_AMOUNT,
  getPromoExpiration,
  getPromoLabelDescription,
  getPromoRewardLabel,
  getPromoRuleLabel,
  getStorePromoAvailableChannel,
  getStorePromoRuleLabel,
  getStorePromoTierType,
  getVoucherInvalidRules,
  PromoCreationFormInterface,
  PromoGrantTypeEnum,
  PromoLabelEnum,
  PromoPaymentMethodEnum,
  PromoRuleTypeEnum,
  RewardExpiryEnum,
  StoreDiscountTypeEnum,
  StorePromoModel,
  StorePromoPurchasedProductInterface,
} from "../../..";

import dayjs from "dayjs";

export interface StorePromoEntitySchemaInterface {
  rule: {
    minimumQuantity?: number;
    masterProductVariantId?: number;
    masterProductVariant?: {
      name: string;
    };
    paymentMethodOptions?: ReadonlyArray<PromoPaymentMethodEnum>;
    minimumSalesAmount: Money;
    label: string;
  };
  reward: {
    nominal?: Money;
    percentage?: number;
    maximum?: Money;
    label: string;
    item: {
      name?: string;
      quantity?: number;
      masterProductVariantId?: number;
    };
    tierType?: string;
    roundingOption?: string;
  };
}

export interface StorePromoInterface {
  data: StorePromoModel;
  /**
   * TODO: Replace with `label`
   */
  ruleType: {
    value: PromoLabelEnum;
    label: string;
  };
  title: string;
  label: {
    value: PromoLabelEnum;
    description: string | null;
  };
  grantedReward:
    | {
        amount: Money;
        amountText?: string;
      }
    | {
        item: {
          name: string;
          quantity: number;
          masterProductVariantId: number;
        };
      }
    | null;
  schemas: ReadonlyArray<StorePromoEntitySchemaInterface>;
  isProductBundlePromo: boolean;
  isCashierPromo: boolean;
  isVoucher: boolean;
  isOnTheFlyDiscount: boolean;
  isInvalid: boolean;
  hasMultipleSchema: boolean;
  createdAt: string;
  updatedAt?: string;
  channel: {
    availableChannel: ReadonlyArray<string>;
    availableCatalog: ReadonlyArray<{
      name: string;
      id: number;
    }>;
  };
  schedule: {
    startDate: string;
    endDate: string;
  } | null;
  expiration?: {
    type: RewardExpiryEnum;
    typeLabel?: string;
    date?: {
      day?: string;
      month?: string;
      expiredDate?: string;
    };
    transaction?: {
      duration?: number;
      period?: {
        label: string;
        value: number;
      };
    };
  };
}

export type ProductPromotionMap = Record<
  number,
  {
    amount: Money;
    promotionName: string;
  }
>;

export const roundingOptionLabelMap = {
  "x00": "Ratusan (x00)",
  "x000": "Ribuan (x000)",
  "x0.000": "Ratusan Ribu (x0.000)",
  "x00.000": "Puluhan Ribu (x00.000)",
};

export class StorePromo implements StorePromoInterface {
  constructor(private storePromo: StorePromoModel) {}

  get data() {
    return this.storePromo;
  }

  get title() {
    if (!this.storePromo) return "-";

    return this.storePromo.title;
  }

  get description() {
    switch (this.data.label) {
      case PromoLabelEnum.ON_THE_FLY_DISCOUNT:
        return this.data.notes ? this.data.notes : "-";
      case PromoLabelEnum.VOUCHER:
        return "Voucher";
      default:
        return "-";
    }
  }

  get voucherCode() {
    return this.storePromo.voucherCode;
  }

  get label() {
    return {
      value: this.storePromo.label,
      description: getPromoLabelDescription(this.storePromo.label),
    };
  }

  get ruleType() {
    return {
      value: this.storePromo.label,
      label: getPromoRuleLabel(this.storePromo?.schemas[0]?.rules[0]?.label),
    };
  }

  get grantedReward() {
    if (!this.data.grantedReward) return null;

    if (this.data.grantedReward.amount) {
      const amount = new Money(this.data.grantedReward.amount, {
        rounded: true,
      });

      return {
        amount,
        amountText: `${amount.currency} ${amount.value}`,
      };
    }

    if (
      this.data.grantedReward.item &&
      Object.keys(this.data.grantedReward.item).length
    ) {
      return {
        item: this.data.grantedReward?.item,
      };
    }

    return null;
  }

  get discountAmount() {
    const { grantedReward, label } = this.data;

    if (!grantedReward) return "-";

    if (
      [
        PromoLabelEnum.PRODUCT_BUY_N_GET_M,
        PromoLabelEnum.PRODUCT_BUNDLING,
      ].includes(label)
    ) {
      return "Gratis Produk";
    }

    const amount = new Money(grantedReward.amount ? -grantedReward.amount : 0, {
      rounded: true,
    });
    return amount.currencyValue;
  }

  get isCashierPromo() {
    return [
      PromoLabelEnum.ON_THE_FLY_DISCOUNT,
      PromoLabelEnum.VOUCHER,
    ].includes(this.label.value);
  }

  get isVoucher() {
    return this.label.value === PromoLabelEnum.VOUCHER;
  }

  get isOnTheFlyDiscount() {
    return this.label.value === PromoLabelEnum.ON_THE_FLY_DISCOUNT;
  }

  get isInvalid() {
    const eligible = this.data.isEligible ?? true;

    return !eligible;
  }

  get hasMultipleSchema() {
    return [
      PromoLabelEnum.MINIMUM_ORDER_QUANTITY,
      PromoLabelEnum.MINIMUM_ORDER_SALES_AMOUNT,
    ].includes(this.data.label);
  }

  get schemas() {
    return this.storePromo.schemas?.map((schema) => {
      const percentage = schema.reward.percentage;
      const maximum = schema.reward.maximum
        ? new Money(schema.reward.maximum, { rounded: true })
        : undefined;
      const nominal =
        schema.reward.percentage === 100 && schema.reward.maximum
          ? new Money(schema.reward.maximum, { rounded: true })
          : undefined;

      // Current case doesn't handle multiple rules
      const [rule] = schema.rules;
      const minimumSalesAmount = new Money(rule?.config.minimumQuantity ?? 0, {
        rounded: true,
      });

      return {
        rule: {
          ...rule?.config,
          minimumSalesAmount,
          label: getStorePromoRuleLabel({
            rule,
            minimumSalesAmount,
            grantType: this.data.grantType,
          }),
        },
        reward: {
          maximum,
          percentage,
          nominal,
          label: getPromoRewardLabel({
            label: this.storePromo.label,
            minimumQuantity: rule?.config.minimumQuantity,
            nominal,
            maximum,
            reward: schema.reward,
          }),
          tierType: getStorePromoTierType({
            label: this.storePromo.label,
            rewardPercentage: schema.reward.percentage,
          }),
          item: {
            name:
              schema.reward.item?.name ??
              rule?.config.masterProductVariant?.name,
            amount: schema.reward.quantity,
            masterProductVariantId: rule?.config.masterProductVariantId,
          },
          roundingOption: schema.reward.roundingOption
            ? roundingOptionLabelMap[schema.reward.roundingOption]
            : undefined,
        },
      };
    });
  }

  get schedule() {
    if (!this.data.schedule) return null;

    return {
      startDate: formatReadableDate(this.data.schedule.startDate),
      endDate: formatReadableDate(this.data.schedule.endDate),
    };
  }

  get days() {
    if (!this.data.schedule) return [];

    return this.data.schedule.days;
  }

  get datesAndTimes() {
    if (!this.data.schedule) return null;

    const formatTime = (date: Date) =>
      `${String(date.getHours()).padStart(2, "0")}:${String(
        date.getMinutes()
      ).padStart(2, "0")}`;

    const toDate = (input: Date) =>
      input instanceof Date ? input : new Date(input);

    const startDate = toDate(this.data.schedule.startDate);
    const endDate = toDate(this.data.schedule.endDate);

    const startTime = !isNaN(startDate.getTime())
      ? formatTime(startDate)
      : "00:00";
    const endTime = !isNaN(endDate.getTime()) ? formatTime(endDate) : "23:59";

    return {
      startDate: this.data.schedule.startDate,
      endDate: this.data.schedule.endDate,
      startTime,
      endTime,
    };
  }

  get promoLocation() {
    return this.storePromo.channels.map((channel) => {
      if (channel.channel === ChannelEnum.OFFLINE) return "offline:all";

      return `melaka_catalog:${channel.id}`;
    });
  }

  get discountTier() {
    return {
      promoRewardType:
        this.data.schemas?.[0]?.reward.percentage === 100
          ? StoreDiscountTypeEnum.FIXED_AMOUNT
          : StoreDiscountTypeEnum.PERCENTAGE,
      discountTiers: this.data.schemas.map((schema) => ({
        minimumValue: schema.rules[0]?.config.minimumQuantity,
        minimumQty: schema.rules[0]?.config.minimumQuantity,
        percentage: schema.reward.percentage,
        nominal: schema.reward.maximum,
        maximum: schema.reward.maximum,
      })),
    };
  }

  get promoRule() {
    return this.data.schemas?.[0]?.rules?.[0]?.label as PromoRuleTypeEnum;
  }

  get productPromoRule() {
    if (!this.data) return;

    return {
      bundlingProduct: {
        name: `${this.data.schemas?.[0]?.reward?.item?.masterProductName} - ${this.data.schemas?.[0]?.reward?.item?.name}`,
        id: this.data.schemas?.[0]?.reward?.item?.masterProductVariantId,
        image: this.data.schemas?.[0]?.reward?.item?.imageUrl,
        sku: this.data.schemas?.[0]?.reward?.item?.sku,
        price: this.data.schemas?.[0]?.reward?.item?.price,
      },
      productDiscount: 0,
      minimum: this.data.schemas?.[0]?.rules?.[0]?.config.minimumQuantity ?? 1,
      productItem: {
        id: this.data.schemas?.[0]?.rules?.[0]?.config.masterProduct?.id,
        masterProductVariantId:
          this.data.schemas?.[0]?.rules?.[0]?.config.masterProductVariant?.id,
        name: `${this.data.schemas?.[0]?.rules?.[0]?.config.masterProduct?.name} - ${this.data.schemas?.[0]?.rules?.[0]?.config.masterProductVariant?.name}`,
        image:
          this.data.schemas?.[0]?.rules?.[0]?.config.masterProductVariant
            ?.imageUrl,
        sku: this.data.schemas?.[0]?.rules?.[0]?.config.masterProductVariant
          ?.sku,
        price:
          this.data.schemas?.[0]?.rules?.[0]?.config.masterProductVariant
            ?.price,
      },
      promoRewardType: this.data.label,
    } as StorePromoPurchasedProductInterface;
  }

  get importedProducts() {
    return this.data.schemas?.map((schema) => {
      return {
        discountPercentage:
          schema.reward.percentage === 100
            ? undefined
            : schema.reward.percentage,
        discountedPrice: schema.reward.maximum,
        masterProductId: schema.rules[0]?.config.masterProduct?.id,
        productName: schema.rules[0]?.config.masterProduct?.name,
        productSku: schema.rules[0]?.config.masterProduct?.sku,
        variant: [
          {
            masterProductVariantId:
              schema.rules[0]?.config.masterProductVariantId,
            price: schema.rules[0]?.config.masterProductVariant?.price,
            variantName: schema.rules[0]?.config.masterProductVariant?.name,
            variantSku: schema.rules[0]?.config.masterProductVariant?.sku,
          },
        ],
      };
    });
  }

  get promoFormDefaultValue(): Partial<PromoCreationFormInterface> | undefined {
    if (!this.storePromo) return;

    const schemas = this.storePromo?.schemas || [];

    const percentageValue = schemas[0]?.reward?.percentage || 0;
    const nominalValue = schemas[0]?.reward?.maximum || 0;
    const isPercentage = percentageValue < 100;

    return {
      name: this.storePromo.title,
      promoLocation: this.storePromo?.channels?.map((channel) => {
        if (channel.channel === ChannelEnum.OFFLINE) return "offline:all";

        return channel.channel + ":" + channel.id;
      }),
      redeemablePointRule: {
        minimum: schemas[0]?.rules[0]?.config.minimumQuantity,
        reward: {
          type: isPercentage ? "percentage" : "nominal",
          value: isPercentage ? percentageValue : nominalValue,
        },
        expiration: this.storePromo?.expiration || {
          type: RewardExpiryEnum.REPETITION_TIME_ANNUALLY,
          format: "DD/MM",
          value: "01/01",
        },
        roundingOption: schemas[0]?.reward.roundingOption
          ? schemas[0].reward.roundingOption
          : undefined,
      },
    };
  }

  get isProductBundlePromo() {
    return (
      this.storePromo.label === PromoLabelEnum.PRODUCT_BUNDLING ||
      this.storePromo.label === PromoLabelEnum.PRODUCT_BUY_N_GET_M
    );
  }

  get createdAt() {
    return dayjs(this.storePromo.createdAt).format("D MMMM YYYY");
  }

  get updatedAt() {
    return dayjs(this.storePromo.updatedAt).format("D MMMM YYYY");
  }

  get channel() {
    return getStorePromoAvailableChannel(this.storePromo.channels);
  }

  get expiration() {
    return getPromoExpiration(this.storePromo.expiration);
  }

  get isMultipleProductDiscount() {
    return (
      this.data.grantType === PromoGrantTypeEnum.PROMO_GRANT_TYPE_ALL &&
      this.data.label === PromoLabelEnum.PRODUCT_DISCOUNT
    );
  }

  /**
   * @deprecated
   * subject of termination once promotion/nominal-loyalty-points released and stable. please use simulatePointV2 instead.
   */
  get simulatePoint() {
    const roundingOptionStringToInteger = {
      "x00": 100,
      "x000": 1000,
      "x0.000": 10000,
      "x00.000": 100000,
    };
    const defaultOrderAmount = 250000;
    const defaultRedeemablePoint = 0;

    let minimum = new Money(defaultOrderAmount, { rounded: true });
    let redeemable = new Money(defaultRedeemablePoint, { rounded: true });
    let roundedNumber = new Money(redeemable.integer, { rounded: true });

    if (!this.data || !this.data.schemas || !this.data.schemas[0]) {
      return {
        minimum,
        redeemable,
        roundedNumber,
      };
    }

    const schema = this.data.schemas[0];
    const rule = this.data.schemas[0].rules[0];

    if (rule && rule.config.minimumQuantity) {
      minimum = new Money(rule.config.minimumQuantity, { rounded: true });
    }

    const percentage = schema.reward.percentage;
    if (percentage) {
      const redeemedValue = (minimum.integer * percentage) / 100;

      redeemable = new Money(Math.floor(redeemedValue), {
        rounded: true,
      });
      roundedNumber = redeemable;
    }

    const roundingOption = schema.reward.roundingOption;
    if (roundingOption) {
      const substractor =
        redeemable.integer % roundingOptionStringToInteger[roundingOption];
      roundedNumber = new Money(redeemable.integer - substractor, {
        rounded: true,
      });
    }

    return {
      minimum,
      redeemable,
      roundedNumber,
    };
  }

  get simulatePointV2() {
    const roundingOptionMap = {
      "x00": 100,
      "x000": 1000,
      "x0.000": 10000,
      "x00.000": 100000,
    };
    const defaultRedeemablePoint = 0;

    const pointSimulation = {
      minimum: new Money(DEFAULT_MINIMUM_ORDER_AMOUNT, { rounded: true }),
      redeemable: new Money(defaultRedeemablePoint, { rounded: true }),
      roundedNumber: new Money(defaultRedeemablePoint, { rounded: true }),
    };

    if (!this.data?.schemas?.[0]) {
      return pointSimulation;
    }

    const schema = this.data.schemas[0];
    const rule = schema.rules?.[0];

    if (rule && rule.config.minimumQuantity) {
      pointSimulation.minimum = new Money(rule.config.minimumQuantity, {
        rounded: true,
      });
    }

    const { maximum = 0, percentage = 0 } = schema.reward;
    const isRewardPercentage = percentage < 100;

    if (isRewardPercentage) {
      const amountPercentage =
        (pointSimulation.minimum.integer * percentage) / 100;
      const maxPointRetrieval = Math.min(
        pointSimulation.minimum.integer,
        amountPercentage
      );

      pointSimulation.redeemable = new Money(Math.floor(maxPointRetrieval), {
        rounded: true,
      });
      pointSimulation.roundedNumber = pointSimulation.redeemable;

      const roundingOption = schema.reward.roundingOption;
      if (roundingOption) {
        const substractor =
          pointSimulation.redeemable.integer %
          roundingOptionMap[roundingOption];

        pointSimulation.roundedNumber = new Money(
          pointSimulation.redeemable.integer - substractor,
          {
            rounded: true,
          }
        );
      }
    }

    if (!isRewardPercentage) {
      const maxPointRetrieval = Math.min(
        pointSimulation.minimum.integer,
        maximum
      );
      const redeemablePoint = new Money(maxPointRetrieval, { rounded: true });

      pointSimulation.redeemable = redeemablePoint;
      pointSimulation.roundedNumber = redeemablePoint;
    }

    return pointSimulation;
  }

  /**
   * @abstract
   * Below is based on the new calculation for nominal loyalty points
   * Use this calculation or move [Calculate for multiplication per point] conditional below inside the `simulatePointV2` when promotion/calculation-nominal-loyalty-points is stable
   */
  get simulatePointV3() {
    const roundingOptionMap = {
      "x00": 100,
      "x000": 1000,
      "x0.000": 10000,
      "x00.000": 100000,
    };
    const defaultRedeemablePoint = 0;
    const defaultMinimumOrderAmount = 100000;

    const pointSimulation = {
      minimum: new Money(defaultMinimumOrderAmount, { rounded: true }),
      redeemable: new Money(defaultRedeemablePoint, { rounded: true }),
      roundedNumber: new Money(defaultRedeemablePoint, { rounded: true }),
    };

    if (!this.data?.schemas?.[0]) {
      return pointSimulation;
    }

    const schema = this.data.schemas[0];
    const rule = schema.rules?.[0];

    if (rule && rule.config.minimumQuantity) {
      pointSimulation.minimum = new Money(rule.config.minimumQuantity, {
        rounded: true,
      });
    }

    const { maximum = 0, percentage = 0 } = schema.reward;
    const isRewardPercentage = percentage < 100;

    if (isRewardPercentage) {
      const amountPercentage =
        (pointSimulation.minimum.integer * percentage) / 100;
      const maxPointRetrieval = Math.min(
        pointSimulation.minimum.integer,
        amountPercentage
      );

      pointSimulation.redeemable = new Money(Math.floor(maxPointRetrieval), {
        rounded: true,
      });
      pointSimulation.roundedNumber = pointSimulation.redeemable;

      const roundingOption = schema.reward.roundingOption;
      if (roundingOption) {
        const substractor =
          pointSimulation.redeemable.integer %
          roundingOptionMap[roundingOption];

        pointSimulation.roundedNumber = new Money(
          pointSimulation.redeemable.integer - substractor,
          {
            rounded: true,
          }
        );
      }
    }

    // Calculate for multiplication per point
    if (!isRewardPercentage) {
      const maxPointRetrieval = Math.floor(
        pointSimulation.minimum.integer / maximum
      );

      const redeemablePoint = new Money(maxPointRetrieval, { rounded: true });

      pointSimulation.redeemable = redeemablePoint;
      pointSimulation.roundedNumber = redeemablePoint;
    }

    return pointSimulation;
  }

  get invalidRules() {
    if (!this.isInvalid) return null;

    switch (this.label.value) {
      case PromoLabelEnum.VOUCHER:
        return getVoucherInvalidRules(this.data.schemas);
      default:
        return "Oops! Promosi tidak memenuhi minimal transaksi.";
    }
  }

  get productPromotionMap() {
    if (this.data.grantedReward?.items) {
      return this.data.grantedReward.items.reduce<ProductPromotionMap>(
        (prev, curr) => {
          return {
            ...prev,
            [curr.masterProductVariantId]: {
              amount: new Money(curr.grantedRewardAmount, {
                rounded: true,
              }),
              promotionName: this.data.title,
            },
          };
        },
        {}
      );
    }

    return {};
  }
}

export default StorePromo;
