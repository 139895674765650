// TODO: fix circular dependencies
// eslint-disable-next-line @nrwl/nx/enforce-module-boundaries
import { PromoLabelEnum } from "@saas/promotion/utils";

import { OnTheFlyDiscountInterface } from "..";
import { StorePromoModel } from "../@machine/cashier/cashier-v2/types";

export type GetPromoDetailsPayloadInput = ReadonlyArray<StorePromoModel>;

export type GetPromoDetailsPayloadOutput = {
  /**
   * Promo ids that are applied including auto, manual and voucher promos
   */
  appliedPromoIds: ReadonlyArray<number>;
  manualPromoIds: ReadonlyArray<number>;
  voucherCodes: ReadonlyArray<string>;
  onTheFlyDiscounts: ReadonlyArray<OnTheFlyDiscountInterface>;
};

export const getPromoDetailsPayload = (
  promos: GetPromoDetailsPayloadInput,
  isEligible?: boolean
): GetPromoDetailsPayloadOutput => {
  return promos.reduce(
    (prev, promo) => {
      if (isEligible && !promo.isEligible) return prev;

      /**
       * Voucher promo
       */
      if (promo.label === PromoLabelEnum.VOUCHER && promo.voucherCode) {
        return {
          ...prev,
          appliedPromoIds: [...prev.appliedPromoIds, promo.id],
          voucherCodes: [...prev.voucherCodes, promo.voucherCode],
        };
      }

      /**
       * On the fly discount promo
       */
      if (
        promo.label === PromoLabelEnum.ON_THE_FLY_DISCOUNT &&
        promo.isManualApply &&
        promo.schemas[0] &&
        promo.schemas[0].reward &&
        promo.grantedReward?.amount
      ) {
        return {
          ...prev,
          onTheFlyDiscounts: [
            ...prev.onTheFlyDiscounts,
            {
              title: promo.title,
              amount: promo.schemas[0].reward.maximum,
              percentage:
                promo.schemas[0].reward.percentage === 100 &&
                promo.schemas[0].reward.maximum
                  ? undefined
                  : promo.schemas[0].reward?.percentage,
              notes: promo.notes || "",
            },
          ],
        };
      }

      /**
       * Manual promo
       */
      if (
        (promo.grantedReward?.amount || promo.grantedReward?.item?.name) &&
        promo.isManualApply &&
        promo.id
      ) {
        return {
          ...prev,
          appliedPromoIds: [...prev.appliedPromoIds, promo.id],
          manualPromoIds: [...prev.manualPromoIds, promo.id],
        };
      }

      /**
       * Auto promo
       */
      if (
        (promo.grantedReward?.amount || promo.grantedReward?.item?.name) &&
        !promo.isManualApply &&
        !!promo.id
      ) {
        return {
          ...prev,
          appliedPromoIds: [...prev.appliedPromoIds, promo.id],
        };
      }

      return prev;
    },
    {
      appliedPromoIds: [],
      manualPromoIds: [],
      voucherCodes: [],
      onTheFlyDiscounts: [],
    } as GetPromoDetailsPayloadOutput
  );
};

export default getPromoDetailsPayload;
